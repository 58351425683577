<template>
	<div class="card py-2">
		<div class="tabs px-2 mb-1">
			<ul class="nav nav-tabs border-bottom">
				<li class="nav-item">
					<button class="nav-link" :class="{ 'active': tab === 'details' }"
						@click="() => tab = 'details'">Details
					</button>
				</li>
				<li class="nav-item">
					<button class="nav-link" :class="{ 'active': tab === 'portfolio' }"
						@click="() => tab = 'portfolio'">Portfolio Images
					</button>
				</li>
			</ul>
		</div>
		<section v-if="tab === 'details'">
			<b-card-form>
				<b-row class="justify-content-end">
					<div class="mr-2 text-dark font-bold">Mark as Top Designer</div>
					<b-form-checkbox v-model="professional.details.isTopDesigner" name="check-button" switch
						@change="handleSwitchChange(professional.details.isTopDesigner)" />
				</b-row>
				<!-- First Name and Last Name -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="First Name">
							<b-form-input v-model="professional.details.firstName" placeholder="First Name" readonly />
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Last Name">
							<b-form-input v-model="professional.details.lastName" placeholder="Last Name" readonly />
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Email and Phone -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Email Address">
							<b-form-input type="email" v-model="professional.details.emailAddress"
								placeholder="Email Address" readonly />
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Phone number">
							<b-form-input v-model="professional.details.cellPhone" placeholder="Email Address"
								readonly />
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Bio -->
				<b-row>
					<b-col class="mb-1">
						<b-form-group label="Bio">
							<b-form-textarea :value="professional.details.bio" placeholder="Bio" rows="4"
								readonly></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>

				<hr>

				<!-- Profession and Country -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Profession">
							<b-form-input v-model="professional.details.profession.text" placeholder="Profession"
								readonly />
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Country">
							<b-form-input v-model="professional.details.country.name" placeholder="Country" readonly />
						</b-form-group>
					</b-col>
				</b-row>

				<!--	Hourly rate and Status	-->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Hourly Rate">
							<b-form-input v-model="professional.hourlyRate" placeholder="Hourly Rate" readonly />
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Status">
							<b-form-input v-model="professional.details.status" placeholder="Status" readonly />
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Last Logged In -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Last Logged In">
							<b-form-input :value="getFormattedDate(professional.details.lastLoggedInUTC)"
								placeholder="Hourly Rate" readonly />
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
					</b-col>
				</b-row>
			</b-card-form>


			<!--	Degrees, Certificates, Linkedin and Website	-->
			<b-card-form
				v-if="professional.degrees || professional.certificates || professional.details.linkedInUrl || professional.details.websiteUrl || professional.details.styles">

				<!-- Styles -->
				<div v-if="professional.details.styles">
					<h3 class="mb-2">Styles</h3>
					<b-row>
						<b-col md="6" xl="6" class="mb-1" v-for="(style, index) of professional.details.styles"
							:key="index">
							<b-form-group :label="'Style ' + (index + 1)">
								<b-form-input :value="style.text" placeholder="Style" readonly />
							</b-form-group>
						</b-col>
					</b-row>
				</div>

				<!-- Degrees -->
				<div v-if="professional.degrees">
					<h3 class="mb-2">Degrees</h3>
					<b-row>
						<b-col md="6" xl="6" class="mb-1" v-for="(professionalDegree, index) of professional.degrees"
							:key="index">
							<b-form-group :label="'Degree ' + (index + 1)">
								<b-form-input
									:value="professionalDegree.educationLevel.text + ' in ' + professionalDegree.degree"
									placeholder="Degree" readonly />
							</b-form-group>
						</b-col>
					</b-row>
				</div>

				<!-- Certificates -->
				<div v-if="professional.certificates">
					<h3 class="mb-2">Certificates</h3>
					<b-row>
						<b-col md="6" xl="6" class="mb-1" v-for="(certificate, index) of professional.certificates"
							:key="index">
							<b-form-group :label="'Certificate ' + (index + 1)">
								<b-form-input :value="certificate" placeholder="Certificate" readonly />
							</b-form-group>
						</b-col>
					</b-row>
				</div>

				<!-- Urls -->
				<div v-if="professional.details.linkedInUrl || professional.details.websiteUrl">
					<h3 class="mb-2">Urls</h3>
					<b-row>
						<!-- Linkedin Url	-->
						<b-col md="6" xl="6" class="mb-1" v-if="professional.details.linkedInUrl">
							<b-form-group label="Linkedin Url">
								<b-form-input v-model="professional.details.linkedInUrl" placeholder="Linkedin Url"
									readonly />
							</b-form-group>
						</b-col>

						<!-- Website Url	-->
						<b-col md="6" xl="6" class="mb-1" v-if="professional.details.websiteUrl">
							<b-form-group label="Website Url">
								<b-form-input v-model="professional.details.websiteUrl" placeholder="Website Url"
									readonly />
							</b-form-group>
						</b-col>
					</b-row>
				</div>
			</b-card-form>

			<!-- Files -->
			<b-card-form>
				<h3>Files</h3>
				<b-row>
					<!-- resume -->
					<b-col md="4" xl="4" class="mb-1" v-if="professional.details.resumeUrl">
						<div class="d-flex flex-wrap align-items-center border mr-5 mt-2 p-3">
							<div class="d-flex align-items-center">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">Resume</h6>
								</div>
							</div>
							<div class="ml-auto">
								<b-button variant="flat-info" class="btn-icon" :href="professional.details.resumeUrl"
									target="_blank">
									<feather-icon icon="DownloadCloudIcon" />
								</b-button>
							</div>
						</div>
					</b-col>

					<!-- cover letter -->
					<b-col md="4" xl="4" class="mb-1" v-if="professional.details.coverLetterUrl">
						<div class="d-flex flex-wrap align-items-center border mr-5 mt-2 p-3">
							<div class="d-flex align-items-center">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">Cover Letter</h6>
								</div>
							</div>

							<div class="ml-auto">
								<b-button variant="flat-info" class="btn-icon"
									:href="professional.details.coverLetterUrl" target="_blank">
									<feather-icon icon="DownloadCloudIcon" />
								</b-button>
							</div>
						</div>
					</b-col>

					<!-- portfolio -->
					<b-col md="4" xl="4" class="mb-1" v-if="professional.details.portfolioUrl">
						<div class="d-flex flex-wrap align-items-center border mr-5 mt-2 p-3">
							<div class="d-flex align-items-center">
								<i class="fa-regular fa-file fa-2x"></i>

								<div class="ml-2">
									<h6 class="mb-0">Portfolio</h6>
								</div>
							</div>

							<div class="ml-auto">
								<b-button variant="flat-info" class="btn-icon" :href="professional.details.portfolioUrl"
									target="_blank">
									<feather-icon icon="DownloadCloudIcon" />
								</b-button>
							</div>
						</div>
					</b-col>
				</b-row>
			</b-card-form>
		</section>

		<section v-if="tab === 'portfolio'">
			<div v-for="item in portfolioItems"
				class="portfolio-item-container d-flex flex-row justify-content-between align-items-center">
				<div class=" d-flex flex-row justify-content-start align-items-center w-75">
					<div class="p-3">
						<b-img :src="item.url" width="300px" />
					</div>
					<div class="d-flex flex-column w-100 p-3">
						<div class="d-flex flex-column w-100 mb-3">
							<div class="mb-1 font-weight-bold"> Add/Edit Tags </div>
							<div>
								<v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" taggable multiple
									:reduce="reduceTag" :options="tags" label="name" v-model="item.tags"
									:create-option="createTag" @option:selected="(tag) => optionSelected(tag, item)" />
							</div>

							<div class="validation-text d-flex flex-row justify-content-end w-100 mt-1">
								Max 5
							</div>
						</div>
						<div class="d-flex flex-column w-100 mb-3">
							<div class="font-weight-bold">
								<b-form-group label="Add Alt Text (optional)">
									<b-form-input v-model="item.altText" placeholder="Alt Text" />
								</b-form-group>
							</div>
							<div v-if="item.altTextError"
								class="validation-text-error d-flex flex-row justify-content-end w-100">
								{{ item.altTextError }}
							</div>
							<div v-else class="validation-text d-flex flex-row justify-content-end w-100">
								At least 12 characters
							</div>
						</div>
					</div>
				</div>
				<div class="action-button-container d-flex flex-column p-3">
					<b-button size="md" variant="primary" @click="() => saveTags(item.id)">
						<span class="align-middle">Save Changes</span>
					</b-button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import { BButton, BCol, BFormGroup, BFormInput, BFormTextarea, BRow, BFormCheckbox, BImg } from 'bootstrap-vue';
import moment from 'moment';
import vSelect from 'vue-select'

import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
	props: ['professionalId'],

	components: {
		BCardForm,
		BFormInput,
		BFormGroup,
		BFormTextarea,
		BRow,
		BCol,
		BButton,
		BFormCheckbox,
		BImg,
		vSelect
	},

	data() {
		return {
			professional: {
				details: {
					firstName: null,
					lastName: null,
					emailAddress: null,
					cellPhone: null,
					personalImageUrl: null,
					profession: {
						value: null,
						text: null,
					},
					country: {
						code: null,
						name: null,
						numericCode: null,
						dialCode: null,
					},
					status: null,
					styles: [
						{
							value: null,
							text: null
						},
					],
					bio: null,
					lastLoggedInUTC: null,
					resumeUrl: null,
					coverLetterUrl: null,
					portfolioUrl: null,
					websiteUrl: null,
					linkedInUrl: null,
					isTopDesigner: null
				},
				hourlyRate: null,
				degrees: [
					{
						educationLevel: {
							value: null,
							text: null,
						},
						degree: null,
					}
				],
				certificates: null,
			},
			tab: 'details',
			tags: [],
			portfolioItems: [],
			inputTimer: null,
		}
	},

	methods: {
		getFormattedDate(dateTime) {
			const localDate = moment(dateTime).local();
			const formattedDate = localDate.format("YYYY-MM-DD, h:mm:ss A");

			return formattedDate || null;
		},
		async handleSwitchChange(checked) {
			await this.$http.put(`professionals/${this.professionalId}/TopDesigner`,
				{ IsTopDesigner: checked });

			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Updated Successfully',
					icon: 'CheckCircleIcon',
					variant: 'success',
				},
			});
		},
		createTag(tag) {
			const tagName = tag.trim().toLowerCase()

			const found = this.tags.find(x => x.name === tagName)

			console.log('FOUND: ', found);
			if (!found) {
				console.log('NEW: ');
				return {
					name: tagName
				}
			} else {
				console.log('EXISTING: ');
				return found
			}
		},
		reduceTag(option) {
			return { ...option, name: option.name.trim().toLowerCase() }
		},
		handleOptionCreated(option) {
			const newTag = {
				id: null,
				...option
			}
			this.tags.push(newTag)
			return option
		},
		optionSelected(tag, item) {
			if (item.tags.length > 5) {
				item.tags.pop();
			}
		},
		async saveTags(itemId) {
			const item = this.portfolioItems ? this.portfolioItems.find(x => x.id === itemId) : []
			if (item.altText && item.altText.length < 12) {
				item.altTextError = "Must be at least 12 characters"
				return
			} else {
				item.altTextError = ''
			}
			const tags = item.tags ?? []
			await this.$http.post(`professionals/${this.professionalId}/PortfioloItems/${itemId}/tags`,
				{ tags: tags, altText: item.altText });
			await this.onCreated();
		},
		async onCreated() {
			try {
				const professionalPromise = this.$http.get(`professionals/${this.professionalId}`);
				const tagsPromise = this.$http.get('/Lookups/PortfolioItemTagList');

				const [professional, tags] = await Promise.all([professionalPromise, tagsPromise]);

				this.professional = professional.data;
				this.tags = tags && tags.data?.length ? tags.data : [];

				this.portfolioItems = professional.data.designProfolioItems.map(x => ({
					...x,
					altTextError: ''
				}));
			} catch (error) {
			}
		}
	},

	async created() {
		await this.onCreated()
	}
}



</script>


<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@core/scss/base/components/include'; // Components includes
@import '~@core/scss/base/core/colors/palette-variables.scss';

.tabs {
	.nav-link {
		background-color: white;
	}
}

.portfolio-item-container {
	min-height: 300px;
	border: 1px solid $border-color;
	margin: 0 10px;

	.action-button-container {
		min-height: 300px;
	}
}

.validation-text {
	font-size: 12px
}

.validation-text-error {
	font-size: 12px;
	color: red;
}
</style>
